import "./Home.css";

// hooks
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// components
import Loading from "src/components/loading/Loading";
import { Message } from "src/components/message/Message";
import SimulatorForm from "src/components/simulatorForm";
import Search from "src/components/Search";
import backendURL from "src/components/BackendURL";

// shadcn
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";

type formData = {
    segmentation: string | undefined;
    income: number | null;
    wishValue: number | null;
    bidValue: number | null;
    type: string;
    isAgro: string;
    selectedOption: 'sim' | 'não' | '';
};

type SearchData = {
    group: number | null;
    type: string;
};

type apiData = formData | SearchData;

function Home() {
    const navigate = useNavigate();
    const email = sessionStorage.getItem('email');
    const name = sessionStorage.getItem('name');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [msg, setMsg] = useState<string>('');
    const [whatTab, setWhatTab] = useState<boolean>(() => {
        const savedTab = localStorage.getItem('selectedTab');
        return savedTab === 'search';
    });

    const [formData, setFormData] = useState<formData>({
        segmentation: "",
        income: null,
        wishValue: null,
        bidValue: null,
        type: 'simulation',
        isAgro: "",
        selectedOption: ""
    });

    const [group, setGroup] = useState<number | null>(null);

    let backendUrl: string = '';

    useEffect(() => {
        if (!email) {
            navigate('/login');
        }
    }, [email, navigate]);

    const handleFormChange = (field: string, value: any) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };

    const handleTabChange = (value: string) => {
        const isSearchTab = value === 'search';
        setWhatTab(isSearchTab);
        localStorage.setItem('selectedTab', value);
    };

    const handleSubmit = async (data: apiData) => {
        setLoading(true);
        backendUrl = 'group' in data ? backendURL("search") : backendURL("simulate");

        if (!backendUrl) {
            console.error("A URL do backend não está definida.");
            setLoading(false);
            return;
        }

        let wish_to_string: any;
        let income_to_string: any;

        if ('segmentation' in data) {
            if (data.segmentation)
                data.segmentation = data.segmentation.split(' - ')[0];
            if (data.wishValue) {
                wish_to_string = String(data.wishValue)
                wish_to_string = wish_to_string.replace(/00$/, '');
                data.wishValue = Number(wish_to_string);
            }
            if (data.income) {
                income_to_string = String(data.income)
                income_to_string = income_to_string.replace(/00$/, '');
                data.income = Number(income_to_string)
            }
            if (data.bidValue) {
                data.bidValue = Number(data.bidValue);
            } else {
                data.bidValue = null;
            }
            data.wishValue = Number(data.wishValue);

            if (data.income) {
                data.income = Number(data.income);
            } else {
                data.income = null;
            }
        }

        let dataToSend;

        if ('isAgro' in data && 'selectedOption' in data) {
            const { isAgro, selectedOption, ...rest } = data;

            if (data.bidValue === null && data.income === null) {
                const { bidValue, income, ...filteredRest } = rest;
                dataToSend = filteredRest;
            } else if (data.bidValue === null) {
                const { bidValue, ...filteredRest } = rest;
                dataToSend = filteredRest;
            } else if (data.income === null) {
                const { income, ...filteredRest } = rest;
                dataToSend = filteredRest;
            } else {
                dataToSend = rest;
            }
        }

        const sendingData = Object.keys(dataToSend || {}).length > 0 ? dataToSend : data;

        try {
            const response = await fetch(backendUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(sendingData),
            });

            if (!response.ok) {
                const errorResult: any = await response.json()
                const errorMsg = response.status === 500
                    ? "Ocorreu um erro, por favor tente novamente mais tarde."
                    : errorResult.Message;
                setMsg(errorMsg);
                setError(true);
            } else {
                const result = await response.json();
                navigate("/result", { state: { responseData: result } });
            }

            if ('wishValue' in data) {
                wish_to_string = data.wishValue?.toFixed(0) + '00'
                data.wishValue = Number(wish_to_string);
                income_to_string = data.income?.toFixed(0) + '00'
                data.income = Number(income_to_string)
            }

        } catch (error) {
            console.error('Error sending data:', error);
            setMsg("Erro ao enviar os dados. Tente novamente mais tarde.");
            setError(true);
        } finally {
            setLoading(false);
            setTimeout(() => setError(false), 16000);
        }
    };

    return (
        <div className={loading ? 'container-loading' : 'container'}>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <h1>Olá {name}!</h1>
                    <h3>Realize sua simulação ou pesquisa e receba o resultado instantâneamente</h3>
                    <Tabs defaultValue={whatTab ? 'search' : 'form'} onValueChange={handleTabChange}>
                        <TabsList className="tablist">
                            <TabsTrigger value="form">Simulação</TabsTrigger>
                            <TabsTrigger value="search">Grupo</TabsTrigger>
                        </TabsList>
                        <TabsContent value="form">
                            <SimulatorForm
                                formData={formData}
                                onFormChange={handleFormChange}
                                onSubmit={handleSubmit}
                            />
                        </TabsContent>
                        <TabsContent value="search">
                            <Search
                                group={group}
                                setGroup={setGroup}
                                onSearchSubmit={handleSubmit}
                            />
                        </TabsContent>
                        {error && <Message msg={msg} type="error" />}
                    </Tabs>
                </div>
            )}
        </div>
    );
}

export default Home;
